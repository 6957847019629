/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-04 15:01:07
 * @LastEditTime: 2019-09-05 10:54:50
 * @LastEditors: Please set LastEditors
 */
//axios && restful
import axios from 'axios'
import { Modal, notification } from 'antd'

// axios 配置
axios.defaults.timeout = 60000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.withCredentials = true

// function debounce(fn, wait) {
//   var timer = 0
//   return function() {
//     console.log(1)
//     var that = this
//     var arg = arguments
//     if (timer) {
//       clearTimeout(timer)
//     }
//     timer = setTimeout(function() {
//       fn.apply(that, arg)
//     }, wait)
//   }
// }

//声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let pending = []
let cancelToken = axios.CancelToken
let timeoutLock = true
let removePending = ever => {
	for (var p in pending) {
		//当当前请求在数组中存在时执行函数体
		if (pending[p].u === ever.url + '&' + ever.method) {
			//执行取消操作
			pending[p].f()
			//把这条记录从数组中移除
			pending.splice(p, 1)
		}
	}
}
// 请求拦截器
axios.interceptors.request.use(
	config => {
		//在一个ajax发送前执行一下取消操作
		removePending(config)
		// 这里的ajax标识我是用请求地址&请求方式拼接的字符串
		config.cancelToken = new cancelToken(c => {
			pending.push({
				u: config.url + '&' + config.method,
				f: c
			})
		})
		return config
	},
	err => {
		Modal.error({
			title: '请求失败',
			content: '参数错误'
		})
		return Promise.reject(err)
	}
)

// 响应拦截器
axios.interceptors.response.use(
	res => {
		const response = res.data
		//在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
		removePending(res.config)
		return response
	},
	err => {
		if (err && err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1) {
			if (timeoutLock) {
				notification.info({
					message: '提示',
					description: '网络连接出错，请检查网络后再试'
				})
			}
			timeoutLock = false
		}
		if (err && err.response) {
			if ((err.response.config.url + '').indexOf('login') !== -1) {
				if (err.response.status === 403) {
					Modal.error({
						title: '错误',
						content: err.response.data.userMessage || '登录失败，该账号超出使用权限'
					})
				} else {
					Modal.error({
						title: '错误',
						content: err.response.data.userMessage || '登录出错，请联系管理员'
					})
				}

				return Promise.reject(err.response.data)
			} else if ((err.response.config.url + '').indexOf('restrict') !== -1) {
				if (err.response.status === 401) {
					console.log(err.response.data)
					Modal.error({
						title: '错误',
						content: '请求出错，请联系管理员'
					})
				}
			} else {
				switch (err.response.status) {
					case 401:
						notification.info({
							message: '提示',
							description: '登录已过期，请重新登录'
						})
						localStorage.removeItem('wcpa_userinfo')
						break
					case 404:
						Modal.error({
							title: `错误`,
							content: `接口请求异常:${err.response.config.url}, 请重试`
						})
						break
					case 400:
						return Promise.reject(err.response.data)
					case 409:
						return Promise.reject(err.response.data)
					case 403:
						return Promise.reject(err.response.data)
					case 500:
						return Promise.reject(err.response.data)
					default:
						Modal.error({
							title: `错误`,
							content: 'Oops, 出错啦'
						})
						console.log(err)
				}
				return Promise.reject(err.response.data)
			}
		}
		// var config = err.config
		// if (!config || !config.retry) return Promise.reject(err)
		// if (err && err.response && err.response.status === 400) {
		//   return Promise.reject(err)
		// }
		// config.__retryCount = config.__retryCount || 0

		// if (config.__retryCount >= config.retry) {
		//   Modal.error({
		//     title: `错误`,
		//     content: '网络出错，请检查网络后再试',
		//   })
		//   return Promise.reject(err)
		// }

		// config.__retryCount += 1

		// var backoff = new Promise(function(resolve) {
		//   setTimeout(function() {
		//     resolve()
		//   }, config.retryDelay || 1)
		// })

		// return backoff.then(function() {
		//   return axios(config)
		// })
	}
)

export default function ajax(method, url, data, ...config) {
	// 处理请求的url和数据
	data = method === 'get' ? { params: data } : data

	// 发送请求
	return new Promise((resolve, reject) => {
		// console.log(url, data, 'config',...config)
		axios[method](url, data, ...config)
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
	})
}
