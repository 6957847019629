/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-21 09:07:18
 * @LastEditTime: 2019-09-04 09:07:32
 * @LastEditors: Please set LastEditors
 */
const BASE_URL = 'https://data.chinawcpa.com/api/v1'

module.exports = BASE_URL
