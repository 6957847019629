/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-12 18:06:49
 * @LastEditTime: 2019-09-05 14:56:03
 * @LastEditors: Please set LastEditors
 */
const BASE_URL = require('./baseURL')
const API = {
	//用户相关：医生 助理
	USER: `${BASE_URL}/account`,

	//登录
	LOGIN: `${BASE_URL}/entrance/login`,

	//登出
	LOGOUT: `${BASE_URL}/account/logout`,

	//修改密码
	CHANGEPASS: `${BASE_URL}/account/password`,

	//修改解锁码
	UNLOCK: `${BASE_URL}/account/unlock`,


	// 检查解锁码
	CHECK_UNLOCK: `${BASE_URL}/account/unlock/check`,

	//患者
	PARTICIPANT: `${BASE_URL}/participant`,

	//测评
	QUIZ: `${BASE_URL}/quiz`,

	//form label
	LABEL: `${BASE_URL}/label`,

	//question
	QUESTION: `${BASE_URL}/question?limit=40`,

	//answer
	ANSWER: `${BASE_URL}/answer`,

	//因子
	FACTOR: `${BASE_URL}/factor`,

	//二维码URL创建
	QRCODE: `${BASE_URL}/restrict/guest`,

	//二维码匿名登录
	QRCODE_LOGIN: `${BASE_URL}/entrance/restrict`,

	//匿名测评quiz获取
	QRCODE_GETQUIZ: `${BASE_URL}/restrict/quiz`,

	//不支持localstorage
	UNSUPPORTED: `${BASE_URL}/log/unsupported`,

	//注册
	SINUP: `${BASE_URL}/entrance/signup`,

	//上传证件照
	PICTURE: `${BASE_URL}/account/certificate/`,

	//获取手机验证码
	GETPHONECODE: `${BASE_URL}/verification`,

	//测评查询接口
	FIND: `${BASE_URL}/quiz/find`,

	//获取重置验证码
	GETRESETPHONECODE: `${BASE_URL}/entrance/reset-verification`,

	//修改登录密码
	RESETPWD: `${BASE_URL}/entrance/pass/`,

	//报告
	REPORT: `${BASE_URL}/account/report/apply`,

	//报告状态处理
	HANDLEREPORT: `${BASE_URL}/account/report`,

	//查询电话号码是否被注册
	OCCUPIED_PHONE: `${BASE_URL}/entrance/occupied`,

	// 是否同意协议
	TERM_AGREED: `${BASE_URL}/account/term_agreed`,


	// 工作模式
	WORK_MODE: `${BASE_URL}/account/work_mode`,
}
module.exports = API
