/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-21 11:13:28
 * @LastEditTime: 2019-08-28 16:36:51
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from 'react'
import { Typography, Steps } from 'antd'

import './register.css'

import PostIDCard from './postIDCard'
import PostDocument from './postDocument'
import Tips from './tips'
import AccInfo from './accInfo'
import userDao from '../../services/dao/userDao'

const { Title } = Typography
const { Step } = Steps

class register extends Component {
	constructor(props) {
		super(props)
		this.state = {
			current: 0 //step
		}
	}
	changeCurrent = n => {
		this.setState({ current: n })
	}

	/**
	 * 监听页面关闭事件
	 */
	beforeunload = e => {
		e = window.event || e
		// 兼容IE8和Firefox 4之前的版本
		if (e) {
			e.returnValue = '此页面想询问您是否要离开 - 您输入的数据可能不会被保存。'
		}
		// Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
		return '此页面想询问您是否要离开 - 您输入的数据可能不会被保存。'
	}
	componentDidMount() {
		if (this.props.registerCurrent !== -1) {
			this.setState({ current: this.props.registerCurrent })
		}
		window.addEventListener('beforeunload', this.beforeunload)
	}
	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.beforeunload)
	}

	login = async(user_code, password, callback) => {
		const { status, trial_expired } = await userDao.login({
			user_code,
			password
		})
		localStorage.setItem('wcpa_status', status)
		if (typeof status === 'string' && status.toUpperCase() === 'PENDING') {
			localStorage.setItem('wcpa_trial', trial_expired === false ? '1' : '0')
		}
		callback()
	}

	render() {
		const props = {
			updateState: this.props.updateState, //改变login中state，用来改变checkout的值，切换组件
			changeCurrent: this.changeCurrent, //改变current的值
			current: this.state.current,
			authorize: this.props.authorize,
			login: this.login
		}
		//step配置
		const steps = [
			{
				title: '注册须知',
				component: <Tips {...props} />
			},
			{
				title: '账号信息',
				component: <AccInfo {...props} />
			},
			{
				title: '上传身份证',
				component: <PostIDCard {...props} />
			},
			{
				title: '上传证件',
				component: <PostDocument {...props} />
			}
		]
		const { current } = this.state
		return (
			<div className='register-wrap'>
				<>
					<Title level={2} className='title'>
						注册
					</Title>
					<Steps progressDot current={current} className='step'>
						{steps.map(item => (
							<Step key={item.title} title={item.title} />
						))}
					</Steps>
					{steps[current].component}
				</>
			</div>
		)
	}
}

export default register
