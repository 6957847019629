/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-12 18:06:49
 * @LastEditTime: 2019-08-13 10:30:51
 * @LastEditors: Please set LastEditors
 */
import API from '../api'
import ajax from '../axios'
const baseDao = {
	//获取label
	getLabel() {
		return ajax('get', API.LABEL)
	},

	//获取测评题目
	getQuestion(data) {
		return ajax('get', API.QUESTION, data)
	},

	//获取患者信息
	getParticipant(data) {
		return ajax('get', API.PARTICIPANT, data)
	},

	//添加患者基本信息
	addParticipant(data, id) {
		return ajax('patch', `${API.PARTICIPANT}/${id}`, data)
	},

	//获取因子信息
	getFactor(data) {
		return ajax('get', API.FACTOR, data)
	},
	//不支持localstorage
	recordUnsupported(data) {
		return ajax('post', API.UNSUPPORTED, data)
	}
}
export default baseDao
