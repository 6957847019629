/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-19 10:54:58
 * @LastEditTime: 2019-09-06 17:46:10
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from 'react'
import { Form, Icon, Input, Button, Checkbox, notification, Modal, message } from 'antd'
import userDao from '../../services/dao/userDao'
import baseDao from '../../services/dao/baseDao'
import quizDao from '../../services/dao/quizDao'


// import MediaQuery from 'react-responsive'
import Register from '../register/register'
import ResetPwd from '../resetpwd/ResetPwd'
import './login.css'
import { inputBlur } from '../../utils'
// const { Title, Paragraph, Text } = Typography

// const IconFont = Icon.createFromIconfontCN({
// 	scriptUrl: '/iconfont/iconfont.js'
// })
class NormalLoginForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			versionInfo: {} //版本信息
		}
	}

	/*
	 * @param user_code:
	 * @param password:
	 * @param remember_me:
	 * */
	handleSubmit = e => {
		var e = window.event || e
		e.preventDefault()
		this.props.form.validateFields((err, fieldsValue) => {
			if (!err) {
				this.setState({ loading: true })
				userDao
					.login({
						user_code: fieldsValue.user_code,
						password: fieldsValue.password,
						remember_me: fieldsValue.remember
					})
					.then(
						res => {
							if(typeof res.status === 'string') {
								quizDao
								.getOnGoingQuiz({
									limit:300, //每页数量
									page: 1, //页码
								}).then(res=>{
									if(res.hasOwnProperty('total') && Boolean(res.total)){
										message.info('当前还有未完成的测评，请您注意查看')
									}
								})
							}
							localStorage.setItem('wcpa_status', res.status)
							if (typeof res.status === 'string' && res.status.toUpperCase() === 'ACTIVE') {
								// if (!!localStorage.getItem('wcpa_defaultRoute')) {
								// 	// let routePath = localStorage.getItem('wcpa_defaultRoute')
								// 	this.props.authorize(fieldsValue.user_code, '/survey/station')
								// 	return
								// }
								// this.props.updateState('checkout', 'pick')
								this.props.authorize(fieldsValue.user_code, '/survey/station')
							} else if (typeof res.status === 'string' && res.status.toUpperCase() === 'PENDING') {
								// wcpa_trial === 1 在试用期
								localStorage.setItem('wcpa_trial', res.trial_expired === false ? '1' : '0')
								const that = this
								// 审核不通过
								if (typeof res.review_result === 'string' && res.review_result.toUpperCase() === 'REJECTED') {
									Modal.confirm({
										title: '提示',
										content: !!res.userMessage
											? `抱歉，您的审核不通过，原因：${res.userMessage}。请重新上传相关证件，谢谢您的配合`
											: '抱歉，您的审核不通过。请重新上传相关证件，谢谢您的配合',
										onOk() {
											//记录注册用户帐号，注册成功后用户获取用户数据
											localStorage.setItem('wcpa_sign_phoneNum', fieldsValue.user_code)
											that.props.updateState('registerCurrent', 2)
											that.props.updateState('checkout', 'register')
										},
										onCancel: this.props.unAuthorize,
										okText: '上传证件'
									})
									return 
								}

								// 协议不通过
								if (!res.term_agreed) {
									Modal.confirm({
										title: '用户须知',
										content: <div>
											<div style={{textIndent: '2em'}}>注册成功！非常感谢您注册 WCPA 临床躯体症状分类诊断系统。 请仔细阅读软件使用注意事项：</div>
											<div style={{textIndent: '2em'}}>为了帮助您尽快熟悉软件功能,我们提供了三次测评做为“练习”，其结果报告不得作为诊断依据，且系统不会保留练习所产生的数据。如果您需要在诊疗过程中正式使用本软件，请尽快上传您的身份证明和专业资格证明，否则将不能使用“测评报告”的相关功能。</div>
										</div>,
										onOk: async() => {
											await userDao.termAgreed({yes_no: 'yes'})
	
											//记录注册用户帐号，注册成功后用户获取用户数据
											localStorage.setItem('wcpa_sign_phoneNum', fieldsValue.user_code)
											that.props.updateState('registerCurrent', 2)
											that.props.updateState('checkout', 'register')
										},
										onCancel: this.props.unAuthorize,
										okText: '同意',
										cancelText: '不同意'
									  });
									return 
								}

								this.props.authorize(fieldsValue.user_code, '/survey/station')
								// 试用过期 不能登陆
								// if (res.trial_expired) {
								// 	Modal.confirm({
								// 		title: '提示',
								// 		content: '您的试用已到期，如要继续使用本系统，请完善身份信息，感谢您的使用！',
								// 		onOk() {
								// 			//记录注册用户帐号，注册成功后用户获取用户数据
								// 			localStorage.setItem('wcpa_sign_phoneNum', fieldsValue.user_code)
								// 			that.props.updateState('registerCurrent', 2)
								// 			that.props.updateState('checkout', 'register')
								// 		},
								// 		okText: '上传证件',
								// 		onCancel: this.props.unAuthorize,
								// 	})
								// } else {
								// 	this.props.authorize(fieldsValue.user_code, '/survey/station')
								// }
									

									// Modal.confirm({
									// 	title: '提示',
									// 	content: '您现在使用的是试用模式，为了使用全部功能，请您尽快完善证件信息。请注意，试用时长为期一周，试用期内，您可以使用除了“数据导出“之外的所有功能。',
									// 	onOk() {
									// 		//记录注册用户帐号，注册成功后用户获取用户数据
									// 		localStorage.setItem('wcpa_sign_phoneNum', fieldsValue.user_code)
									// 		that.props.updateState('registerCurrent', 2)
									// 		that.props.updateState('checkout', 'register')
									// 	},
									// 	onCancel() {
									// 		that.props.authorize(fieldsValue.user_code, '/survey/station')
									// 	},
									// 	cancelText: '继续试用',
									// 	okText: '上传证件'
									// })
								
							} else if (typeof res.status === 'string' && res.status.toUpperCase() === 'UNCONFIRMED') {
								this.props.authorize(fieldsValue.user_code, '/survey/station')
								// Modal.info({
								// 	title: '提示',
								// 	content: '您的资料及证件已全部上传，请等待管理员审核'
								// })
								// this.props.unAuthorize()
							}
						},
						err => {
							console.log(err)
						}
					)
					.finally(() => {
						this.setState({ loading: false })
					})
			}
		})
	}
	componentDidMount() {
		//是否跳转到注册
		if (this.props.tipRegister) {
			this.props.updateState('checkout', 'register')
			this.props.changeTipRegister(false)
		}
		//获取版本信息
		baseDao
			.getLabel()
			.then(res => {
				if (res.app) {
					// console.log(res)
					const { app, test_accounts } = res
					this.setState({ versionInfo: app, testAcc: test_accounts })
					localStorage.setItem('wcpa_label', JSON.stringify(res))
				}
			})
			.catch(err => {
				notification.info({
					message: '提示',
					description: '网络出错，请检查网络情况后再试'
				})
				console.log('baseDao.getLabel' + err)
			})
	}
	register = () => {
		this.props.updateState('checkout', 'register')
	}
	resetpwd = () => {
		this.props.updateState('checkout', 'resetpwd')
	}
	/**
	 * 判断账号是否为测试账号
	 */
	// isTestAcc = () => {
	// 	this.props.form.validateFields((err, value) => {
	// 		if (!!!this.state.testAcc) {
	// 			Modal.info({
	// 				title: '提示',
	// 				content: '抱歉登录失败，请检查您的网络状态'
	// 			})
	// 		} else if (!err && this.state.testAcc.indexOf(value.user_code) !== -1) {
	// 			Modal.confirm({
	// 				title: '提示',
	// 				content: '体验账号仅供体验系统使用，所传数据不具隐私性，且不可下载。建议您进行实名注册后使用',
	// 				onOk: () => {
	// 					this.props.updateState('checkout', 'register')
	// 				},
	// 				onCancel: () => {
	// 					this.handleSubmit()
	// 				},
	// 				okText: '实名注册',
	// 				cancelText: '继续'
	// 			})
	// 		} else {
	// 			this.handleSubmit()
	// 		}
	// 	})
	// }
	render() {
		const {
			loading,
			versionInfo: { name, support_url, support_by }
		} = this.state
		const { getFieldDecorator } = this.props.form
		return (
			<div className='login-wrap'>
				<Form className='login-form'>
					<h2>{name || 'WCPA 临床躯体症状分类诊断系统'}</h2>
					<Form.Item>
						{getFieldDecorator('user_code', {
							rules: [{ required: true, message: '请输入您的用户名' }],
							initialValue: ''
						})(
							<Input
								prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
								placeholder='手机号码'
								size='large'
								onBlur={inputBlur}
							/>
						)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('password', {
							rules: [{ required: true, message: '请输入您的密码' }],
							initialValue: ''
						})(
							<Input.Password
								prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
								placeholder='密码'
								size='large'
								onBlur={inputBlur}
							/>
						)}
					</Form.Item>
					<Form.Item style={{ margin: 0 }}>
						<div className='remember'>
							{getFieldDecorator('remember', {
								valuePropName: 'checked',
								initialValue: false
							})(<Checkbox>保持登录</Checkbox>)}
							{/* <Tooltip placement="topRight" title={FORGET_PWD} overlayStyle={{ whiteSpace: 'nowrap' }}>
							<Button type="link">忘记密码</Button>
						</Tooltip> */}
							<Button type='link' onClick={this.resetpwd}>
								忘记密码
							</Button>
						</div>
						<div className='btn'>
							<Button
								block
								type='primary'
								htmlType='submit'
								size='large'
								className='login-form-button'
								loading={loading}
								onClick={this.handleSubmit}
							>
								登录
							</Button>
						</div>
						<div style={{ fontSize: 17 }}>
							没有账号？
							<Button type='link' onClick={this.register} style={{ fontSize: 17 }}>
								免费注册
							</Button>
						</div>
					</Form.Item>
				</Form>
				<div style={{ position: 'absolute', bottom: 10, color: '#999', textAlign: 'center', fontSize: 13 }}>
					Powered by{' '}
					<a target='_blank' href={support_url || 'https://www.ligotop.com'} rel='nofollow me noopener noreferrer'>
						{support_by || '四川旅鸽科技有限公司'}
					</a>
				</div>
			</div>
		)
	}
}
const WrappedNormalLoginForm = Form.create()(NormalLoginForm)

// class Pick extends Component {
// 	constructor(props) {
// 		super(props)
// 		this.state = {
// 			tips: {
// 				default: '请选择您进入的工作站',
// 				diag: '诊断端可查看测评结果、医生进行他评、安排新测评、生成测评二维码等',
// 				survey: '测评端可安排新测评、待测人进行自评、医生进行他评等'
// 			},
// 			pink: 'default'
// 		}
// 	}
// 	goto = routePath => {
// 		localStorage.setItem('wcpa_defaultRoute', routePath)
// 		const wcpa_signup_phoneNum = localStorage.getItem('wcpa_signup_phoneNum')
// 		//从登录跳转过来
// 		if (!!wcpa_signup_phoneNum) {
// 			this.props.authorize(wcpa_signup_phoneNum, routePath)
// 		} else {
// 			this.props.authorize(this.props.userCode, routePath)
// 		}
// 	}
// 	handleMouseMove = e => {
// 		if (e.target.textContent.indexOf('医生') !== -1) {
// 			this.setState({ pink: 'diag' })
// 		} else if (e.target.textContent.indexOf('助理') !== -1) {
// 			this.setState({ pink: 'survey' })
// 		}
// 	}
// 	handleMouseOut = () => {
// 		this.setState({
// 			pink: 'default'
// 		})
// 	}
// 	render() {
// 		const { tips, pink } = this.state
// 		return (
// 			<div className='pink-wrap'>
// 				<div
// 					style={{
// 						display: 'flex',
// 						flexDirection: document.documentElement.clientWidth < 768 ? 'column' : 'row'
// 					}}
// 				>
// 					<div
// 						className='pink-box'
// 						onClick={this.goto.bind(this, '/doctor/solution')}
// 						onMouseEnter={e => this.handleMouseMove(e)}
// 						onMouseLeave={this.handleMouseOut}
// 					>
// 						<IconFont type='icon-yisheng' style={{ fontSize: '10em' }} />
// 						<Title level={4}>我是医生</Title>
// 						<Paragraph>点击进入诊断端</Paragraph>
// 					</div>

// 					<div
// 						className='pink-box'
// 						onClick={this.goto.bind(this, '/survey/station')}
// 						onMouseEnter={e => this.handleMouseMove(e)}
// 						onMouseLeave={this.handleMouseOut}
// 					>
// 						<IconFont type='icon-hushi' style={{ fontSize: '10em' }} />
// 						<Title level={4}>我是助理</Title>
// 						<Paragraph>点击进入测评端</Paragraph>
// 					</div>
// 				</div>

// 				<MediaQuery minWidth={768}>
// 					<div style={{ marginTop: 14, transition: 'all 0.15s ease' }}>
// 						<Text type='secondary'>{tips[pink]}</Text>
// 					</div>
// 				</MediaQuery>
// 			</div>
// 		)
// 	}
// }

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			checkout: 'login',
			registerCurrent: -1
		}
	}

	updateState = (key, state) => {
		let obj = {}
		obj[key] = state
		this.setState(obj)
	}
	render() {
		const { checkout } = this.state
		const props = {
			checkout,
			updateState: this.updateState,
			authorize: this.props.authorize,
			unAuthorize: this.props.unAuthorize,
			tipRegister: this.props.tipRegister,
			changeTipRegister: this.props.changeTipRegister
		}
		return (
			<>
				{checkout === 'register' ? (
					<Register {...props} registerCurrent={this.state.registerCurrent} />
				) : checkout === 'resetpwd' ? (
					<ResetPwd {...props}></ResetPwd>
				) : (
					<WrappedNormalLoginForm {...props} />
				)}
			</>
		)
	}
}

export default Login
