/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-19 10:54:58
 * @LastEditTime: 2019-08-19 11:00:00
 * @LastEditors: Please set LastEditors
 */
export const SVG_WIDTH = 750
export const SVG_HEIGHT = 280
export const IGNORE_HEIGHT = 80
export const OFFSET_X = (function() {
	return document.documentElement.clientWidth < 500 ? 20 : 30
})()
// export const OFFSET_X = 30
// export const WAVE_Y_LIST = [150, 153]
export const TOTAL_INIT_INDEX = 0
export const MAX_POINT = 140
export const UNDER_WAVE_POINT = 30

export const STATUS_CONFIG = {
	idle: 'start',
	progress: 'pause',
	waiting: 'resume',
	whatever: 'close'
}
export const FORGET_PWD = '请联系管理员，联系电话：131XXXX4903'
export const map = {
	statusSelectOptionsMap: [
		{
			name: '未审批',
			value: 'not_yet'
		},
		{
			name: '通过',
			value: 'pass'
		},
		{
			name: '被拒绝',
			value: 'declined'
		}
	],
	statusSelectOptionsMobileMap: [
		{
			label: '未审批',
			value: 'not_yet'
		},
		{
			label: '通过',
			value: 'pass'
		},
		{
			label: '被拒绝',
			value: 'declined'
		}
	],
	//用户管理表格中，状态显示 点的颜色，文本
	statusMap: {
		not_yet: {
			status: 'processing',
			statusText: '未审批'
		},
		pass: {
			status: 'success',
			statusText: '通过'
		},
		declined: {
			status: 'error',
			statusText: '被拒绝'
		}
	},
	//根据服务器返回状态，对应操作
	actionMap: {
		not_yet: '/',
		pass: '下载报告',
		declined: '查看原因'
	},
}
export const switchMode = false

export const applyReport_disabledDate = '2019-09-01'

