/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-22 14:37:51
 * @LastEditTime: 2019-09-06 17:43:08
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from 'react'
import { Button, Form, Input, Icon, Modal, message } from 'antd'
import './register.css'
import userDao from '../../services/dao/userDao'

class AccInfo extends Component {
	constructor(props) {
		super(props)
		this.state = {
			confirmDirty: '', //登录密码和确认密码比较
			submitLoading: false, //提交表单时loading
			current: 0, //用于步骤条切换
			phoneCode: false, //禁用获取验证码按钮
			phoneCodeCountDown: 60, //获取验证码倒计时
			isRegister: true//手机号码是否注册
		}
	}

	/**
	 * 验证确认密码
	 */
	compareToFirstPassword = (rule, value, callback) => {
		const { form } = this.props
		if (value && value !== form.getFieldValue('password')) {
			callback('两次输入密码不一致')
			return
		} else {
			callback()
			return
		}
	}

	/**
	 *验证登录密码
	 */
	validateToNextPassword = (rule, value, callback) => {
		const { form } = this.props
		if (value && this.state.confirmDirty) {
			form.validateFields(['confirm'], { force: true })
		}
		if (!value) {
			console.log(111)
			callback()
			return
		}
		if (value.length < 4 || value.length > 20) {
			callback('登录密码长度为4位到20位')
			return
		}
		const exp = /^[a-zA-Z0-9_!@#$%^&*+=-]+$/i
		let validateResult = exp.test(value)
		if (!validateResult || value.indexOf(' ') !== -1) {
			callback('登录密码包含数字字母，以及特殊字符（_!@#$%^&*+=-）')
			return
		}
		callback()
	}


	// 中文姓名检验
	nameTest = (choiceName) => {
		let { length } = choiceName.replace(/[\u4e00-\u9fa5]/g, '**')
		let isReg = (
			/[\u4e00-\u9fa5]/g.test(choiceName) &&
			/^(?![·\-_\s])(?!.*[·\-_\s]$)/.test(choiceName) &&
			/^[·\-\s\w\u4e00-\u9fa5]*$/.test(choiceName) &&
			!/[0-9]/.test(choiceName) &&
			!/[\u4e00-\u9fa5][a-zA-Z]{1}|[a-zA-Z][\u4e00-\u9fa5]{1}/.test(choiceName) &&
			!/[·_\-\s]{2}/.test(choiceName) &&
			!/[\u4e00-\u9fa5]\s[\u4e00-\u9fa5a]/.test(choiceName) &&
			!/[a-z]\s{2,}[a-z]/i.test(choiceName)

		) &&
			2 < length && length < 51;

		if (isReg) {
			return true
		} else {
			return false
		}
	}

	/**
	 * 验证姓名
	 * 1.是否是中文姓名
	 * 
	 */
	validateAcc = (rule, value, callback) => {
		if (!value) {
			callback()
			return
		}
		if (!this.nameTest(value)) {
			callback('请输入正确的中文姓名')
			return
		}
		callback()
	}
	/**
	 * 验证手机号码
	 */
	validatePhoneNumber = (rule, value, callback) => {
		if (!value) {
			callback()
			return
		}
		const exp = /^[1]([3-9])[0-9]{9}$/
		let validateResult = exp.test(value)
		if (!validateResult) {
			callback('请输入正确的手机号码')
			return
		}
		callback()
	}
	/**
	 * 验证身份证号码
	 */
	// validateIdNumber = (rule, value, callback) => {
	// 	if (!value) {
	// 		callback()
	// 	}
	// 	const exp = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
	// 	let validateResult = exp.test(value)
	// 	if (!validateResult) {
	// 		callback('请输入正确的身份证号码')
	// 	}
	// 	callback()
	// }
	/**
	 * 确认密码失焦事件
	 */
	handleConfirmBlur = e => {
		const { value } = e.target
		this.setState({ confirmDirty: this.state.confirmDirty || !!value })
	}
	/**
	 * 验证手机验证码
	 */
	validatePhoneCode = (rule, value, callback) => {
		if (!value) {
			callback()
			return
		}
		if (value.length < 6) {
			callback('请输入6位验证码')
			return
		}
		const exp = /^[0-9]*$/
		let validateResult = exp.test(value)
		if (!validateResult) {
			callback('请输入正确的验证码')
			return
		}
		callback()
	}

	/**
	 * 提交表单
	 */
	handleSubmit = e => {
		e = window.event || e
		e.preventDefault()
		// this.props.changeCurrent(2)
		const { validateFieldsAndScroll } = this.props.form
		validateFieldsAndScroll((err, value) => {
			if (!err) {
				const { name, phoneNumber, password, phoneCode, employer } = value
				let params = {
					phone: phoneNumber,
					name,
					password,
					verification: phoneCode,
					employer
				}
				userDao
					.sinup(params)
					.then(res => {
						if (res) {
							this.props.login(phoneNumber, password, () => {
								Modal.confirm({
									title: '用户须知',
									content: <div>
									<div style={{textIndent: '2em'}}>注册成功！非常感谢您注册 WCPA 临床躯体症状分类诊断系统。 请仔细阅读软件使用注意事项：</div>
									<div style={{textIndent: '2em'}}>为了帮助您尽快熟悉软件功能,我们提供了三次测评做为“练习”，其结果报告不得作为诊断依据，且系统不会保留练习所产生的数据。如果您需要在诊疗过程中正式使用本软件，请尽快上传您的身份证明和专业资格证明，否则将不能使用“测评报告”的相关功能。</div>
									</div>,
									onOk: async() => {
										await userDao.termAgreed({yes_no: 'yes'})

										localStorage.setItem('wcpa_signup_phoneNum', value.phoneNumber)
										this.props.changeCurrent(2)
									},
									onCancel: async() => {
										this.props.updateState('checkout', 'login')
									},
									okText: '同意',
									cancelText: '不同意'
								  });
								
							})
						}
					})
					.catch(err => {
						console.log(err)
						if (err.code === 4402) {
							Modal.error({
								title: '错误',
								content: '您输入的验证码不正确或已失效，请确认后再试'
							})
						} else if (err === 'E_PHONE_USED') {
							Modal.error({
								title: '错误',
								content: '该账号已被注册，请勿再次注册'
							})
						} else {
							Modal.error({
								title: '错误',
								content: '请求失败，请稍后再试'
							})
						}
					})
			}
		})
		// this.props.changeCurrent(2)
	}

	/**
	 * 处理回车事件
	 */
	// handleKeyDown = e => {
	// 	e = window.event || e
	// 	const { current } = this.props
	// 	if (e.keyCode === 13) {
	// 		this.handleSubmit()
	// 	}
	// }
	/**
	 * 手机号码错误，发送验证码按钮不可点击
	 */
	hasErrors = fieldsError => {
		return Object.keys(fieldsError).some(field => fieldsError[field])
	}
	/**
	 * 获取手机验证码
	 */
	getPhoneNumber = () => {
		this.props.form.validateFields(['phoneNumber'], (err, value) => {
			if (!err) {
				userDao.occupiedPhone({}, value.phoneNumber).then(res => {
					if (res.available === true) {
						this.setState({ isRegister: false })
						return userDao.getPhoneCode({}, value.phoneNumber)
					} else {
						Modal.confirm({
							title: '提示',
							content: '该手机号码已被注册',
							onOk: () => {
								this.props.updateState('checkout', 'login')
							},
							okText: '点击登录',
						})
					}
				}).then(res => {
					const { isRegister } = this.state
					if (!isRegister) {
						this.setState({ phoneCode: true })
						this.timer = setInterval(() => {
							if (this.state.phoneCodeCountDown !== 0) {
								this.setState((prevState, props) => ({
									phoneCodeCountDown: prevState.phoneCodeCountDown - 1
								}))
							} else {
								clearInterval(this.timer)
								this.setState({ phoneCode: false, phoneCodeCountDown: 60 })
							}
						}, 1000)
					}
				})
					.catch(err => {
						console.log('getPhoneCode：' + err)
						if (err.code === 4403) {
							Modal.error({
								title: '错误',
								content: err.userMessage
							})
						} else {
							Modal.error({
								title: '错误',
								content: '获取手机验证码失败，请稍后再试'
							})
						}
					})
			}
		})
	}

	componentWillUnmount() {
		clearInterval(this.timer)
	}
	render() {
		//账号信息表单配置
		const accinfo = [
			// {
			// 	labelName: '用户名',
			// 	itemName: 'acc',
			// 	options: {
			// 		rules: [
			// 			{
			// 				required: true,
			// 				message: '用户名不能为空'
			// 			},
			// 			{
			// 				validator: this.validateAcc
			// 			}
			// 		],
			// 		validateTrigger: 'onBlur',
			// 		initialValue: '20121221'
			// 	},
			// 	component: <Input placeholder="请输入用户名" maxLength={14} autoFocus />
			// },
			{
				labelName: '姓名',
				itemName: 'name',
				options: {
					rules: [
						{
							required: true,
							message: '姓名不能为空'
						},
						{
							validator: this.validateAcc
						}
					]
					// validateTrigger: 'onBlur'
				},
				component: <Input placeholder='请输入真实姓名' maxLength={10} autoFocus />,
				hasFeedback: true
			},
			{
				labelName: '手机号码',
				itemName: 'phoneNumber',
				options: {
					rules: [
						{
							required: true,
							message: '手机号码不能为空'
						},
						{
							validator: this.validatePhoneNumber
						}
					]
				},
				component: (
					<div>
						<Input placeholder='请输入手机号码' maxLength={11} />
						<Button
							onClick={this.getPhoneNumber}
							disabled={this.hasErrors(this.props.form.getFieldsError(['phoneNumber'])) || this.state.phoneCode}
						>
							获取验证码
						</Button>
					</div>
				),
				extra: this.state.phoneCode ? `请等待 ${this.state.phoneCodeCountDown} 秒后再次获取验证码` : ''
			},
			{
				labelName: '验证码',
				itemName: 'phoneCode',
				options: {
					rules: [
						{
							required: true,
							message: '验证码不能为空'
						},
						{
							validator: this.validatePhoneCode
						}
					]
					// validateTrigger: 'onBlur'
				},
				component: <Input placeholder='请输入验证码' maxLength={6} />,
				hasFeedback: true
			},
			{
				labelName: '登录密码',
				itemName: 'password',
				options: {
					rules: [
						{
							required: true,
							message: '登录密码不能为空'
						},
						{
							validator: this.validateToNextPassword
						}
					]
					// validateTrigger: 'onBlur'
				},
				component: <Input.Password placeholder='请输入密码' maxLength={20} />,
				hasFeedback: true
			},
			{
				labelName: '确认密码',
				itemName: 'confirm',
				options: {
					rules: [
						{
							required: true,
							message: '确认密码不能为空'
						},
						{
							validator: this.compareToFirstPassword
						}
					]
					// validateTrigger: 'onBlur'
				},
				component: <Input.Password placeholder='请确认密码' maxLength={20} onBlur={this.handleConfirmBlur} />,
				hasFeedback: true
			},
			{
				labelName: '工作单位（全称）',
				itemName: 'employer',
				options: {
					rules: [
						{
							required: true,
							message: '工作单位不能为空'
						}
					]
				},
				component: <Input />,
				hasFeedback: true
			},
		]
		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 6 }
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 }
			}
		}
		// const tailFormItemLayout = {
		// 	wrapperCol: {
		// 		xs: {
		// 			span: 24,
		// 			offset: 0
		// 		},
		// 		sm: {
		// 			span: 16,
		// 			offset: 6
		// 		}
		// 	}
		// }
		const {
			form: { getFieldDecorator }
		} = this.props
		return (
			<div className='register-form'>
				<div className='body'>
					<Form {...formItemLayout}>
						<div className='accinfo'>
							<div>
								{accinfo.map((item, index) => {
									return (
										<Form.Item
											label={item.labelName}
											hasFeedback={item.hasFeedback ? true : false}
											key={index}
											extra={item.extra}
										>
											{getFieldDecorator(item.itemName, item.options)(item.component)}
										</Form.Item>
									)
								})}
							</div>
							<div className='btn'>
								<Button onClick={this.props.changeCurrent.bind(this, 0)}>
									<Icon type='left' />
									上一步
								</Button>
								<Button
									type='primary'
									onClick={this.handleSubmit}
									disabled={this.hasErrors(this.props.form.getFieldsError())}
								>
									下一步
									<Icon type='right' />
								</Button>
							</div>
						</div>
						{/* <div className={current === 2 ? 'userinfo active' : 'userinfo'}>
							<div>
								{userinfo.map((item, index) => {
									return (
										<Form.Item
											label={item.labelName}
											hasFeedback={item.hasFeedback ? true : false}
											key={index}
											extra={item.extra}
										>
											{getFieldDecorator(item.itemName, item.options)(item.component)}
										</Form.Item>
									)
								})}
							</div>

							<div className="btn">
								<Button onClick={this.changeCurrent.bind(this, 1)}>
									<Icon type="left" />
									上一步
								</Button>
								<Button type="primary" loading={submitLoading} htmlType="submit">
									提交
									<Icon type="rocket" />
								</Button>
							</div>
						</div> */}
					</Form>
				</div>
			</div>
		)
	}
}
export default AccInfo = Form.create({ name: 'AccInfo' })(AccInfo)
