/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-16 17:46:03
 * @LastEditTime: 2019-09-05 10:53:57
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from 'react'
import { Select } from 'antd';


import moment from 'moment'
import crypto from 'crypto'
// import quizDao from '../services/dao/quizDao'
import { SVG_HEIGHT, OFFSET_X, TOTAL_INIT_INDEX, STATUS_CONFIG } from './../config/constant'
const { Option } = Select;
export function getFormatDate(time, format) {
	return moment(time).utc().format(format)
}

export function getFormatDateMoment(time, format) {
	return moment(time, format)
}

// export const getBefore1Day = format => {
//   return moment(new Date(), format).subtract(1, 'days')
// }
// export const getAfter1Day = format => {
//   return moment(new Date(), format).subtract(-1, 'days')
// }
// /**
//  *查询通用函数
//  * @param {*} obj 查询的数据
//  * @param {*} fn 回调
//  */
// export function searchData(obj, fn) {
// 	let field = obj.field //查询字段名
// 	let departmentID = obj.departmentID //科室ID
// 	let queryStr = obj.queryStr //查询字符串
// 	if (!departmentID || !queryStr || !field) {
// 		console.log('查询参数错误')
// 	}
// 	quizDao
// 		.searchQuiz(queryStr, departmentID, field)
// 		.then(res => fn(res))
// 		.catch(err => console.log('searchTableData' + err))
// }

/**
 * hash转obj
 * @param {*} str
 */
export const queryStr2Obj = str => {
	let obj = {}
	str
		.split('?')[1]
		.split('&')
		.forEach(item => {
			let arr = item.split('=')
			obj[arr[0]] = arr[1]
		})
	return obj
}

/**
 * aes密码加盐加密
 * @param {*} data
 * @param {*} key
 */
export function aesEncrypt(data, key) {
	const cipher = crypto.createCipher('aes192', key)
	var crypted = cipher.update(data + '', 'utf8', 'hex')
	crypted += cipher.final('hex')
	return crypted
}

/**
 * 解密
 * @param {*} encrypted
 * @param {*} key
 */
export function aesDecrypt(encrypted, key) {
	const decipher = crypto.createDecipher('aes192', key)
	var decrypted = decipher.update(encrypted, 'hex', 'utf8')
	decrypted += decipher.final('utf8')
	return decrypted
}

/**
 * 小数保留两位小数，不满用0补足
 * @param {*} x
 */
export function changeTwoDecimal(x) {
	let f_x = parseFloat(x)
	if (isNaN(f_x)) {
		return 0
	}
	f_x = Math.round(x * 100) / 100
	let s_x = f_x.toString()
	let pos_decimal = s_x.indexOf('.')
	if (pos_decimal < 0) {
		pos_decimal = s_x.length
		s_x += '.'
	}
	while (s_x.length <= pos_decimal + 2) {
		s_x += '0'
	}
	return s_x
}

export function getPoint(x, y, width) {
	const xn = 6 //x轴分隔数
	const yn = 10 //y轴分隔数
	const padding = 10 //y轴原点下留出距离
	const xGrid = (width - OFFSET_X) / xn / 2 //x轴一块的一半大小
	const yGrid = SVG_HEIGHT / (yn + 1) / 10 //y轴单位1大小
	let cy = 0 //点的y轴位置
	let svgYscale = [] //坐标分隔
	let wave_y_list = [] //波浪线位置
	let criticalvalue = []
	//总分单独考虑
	if (x === TOTAL_INIT_INDEX) {
		// 临界值
		criticalvalue = [
			yGrid * (80 + padding),
			yGrid * (90 + padding) + 5 * yGrid,
			yGrid * (90 + padding) + 1 * yGrid,
			yGrid * (90 + padding) + 2 * yGrid,
			yGrid * (90 + padding) + 6 * yGrid
		]
		if (y >= 0 && y < 80) {
			svgYscale = [
				{ dy: yGrid * (100 + padding), text: '0' },
				{ dy: yGrid * (90 + padding), text: '10' },
				{ dy: yGrid * (80 + padding), text: '20' },
				{ dy: yGrid * (70 + padding), text: '30' },
				{ dy: yGrid * (60 + padding), text: '40' },
				{ dy: yGrid * (50 + padding), text: '50' },
				{ dy: yGrid * (40 + padding), text: '60' },
				{ dy: yGrid * (30 + padding), text: '70' },
				{ dy: yGrid * (20 + padding), text: '80' },
				{ dy: yGrid * (10 + padding), text: '130' },
				{ dy: yGrid * (0 + padding), text: '140' }
			]
			wave_y_list = [yGrid * (20 + padding) - 4 * yGrid, yGrid * (10 + padding) + 4 * yGrid]
			cy = (padding + 140 - 40 - y) * yGrid
		} else if (y >= 80 && y < 90) {
			svgYscale = [
				{ dy: yGrid * (100 + padding), text: '0' },
				{ dy: yGrid * (90 + padding), text: '10' },
				{ dy: yGrid * (80 + padding), text: '20' },
				{ dy: yGrid * (70 + padding), text: '30' },
				{ dy: yGrid * (60 + padding), text: '40' },
				{ dy: yGrid * (50 + padding), text: '50' },
				{ dy: yGrid * (40 + padding), text: '60' },
				{ dy: yGrid * (30 + padding), text: '70' },
				{ dy: yGrid * (20 + padding), text: '80' },
				{ dy: yGrid * (10 + padding), text: '90' },
				{ dy: padding * yGrid, text: '140' }
			]
			wave_y_list = [yGrid * (10 + padding) - 4 * yGrid, padding * yGrid + 4 * yGrid]
			cy = (padding + 140 - 40 - y) * yGrid
		} else if (y >= 90 && y <= 140) {
			svgYscale = [
				{ dy: yGrid * (100 + padding), text: '0' },
				{ dy: yGrid * (90 + padding), text: '10' },
				{ dy: yGrid * (80 + padding), text: '20' },
				{ dy: yGrid * (70 + padding), text: '30' },
				{ dy: yGrid * (60 + padding), text: '40' },
				{ dy: yGrid * (50 + padding), text: '90' },
				{ dy: yGrid * (40 + padding), text: '100' },
				{ dy: yGrid * (30 + padding), text: '110' },
				{ dy: yGrid * (20 + padding), text: '120' },
				{ dy: yGrid * (10 + padding), text: '130' },
				{ dy: padding * yGrid, text: '140' }
			]
			wave_y_list = [yGrid * (60 + padding) - 4 * yGrid, yGrid * (50 + padding) + 4 * yGrid]
			cy = (padding + 140 - y) * yGrid
		}
	} else {
		cy = (padding + 140 - 40 - y) * yGrid
	}
	return {
		cx: (2 * x + 1) * xGrid,
		cy,
		svgYscale,
		wave_y_list,
		criticalvalue
	}
}

/**
 * 异步组件
 * @param {*} importComponent
 */
export const asyncComponent = importComponent => {
	return class extends Component {
		constructor() {
			super()
			this.state = {
				component: null
			}
		}

		componentDidMount() {
			importComponent().then(cmp => {
				this.setState({
					component: cmp.default
				})
			})
		}

		render() {
			const C = this.state.component
			return C ? <C {...this.props} /> : null
		}
	}
}

export const getAction = status => STATUS_CONFIG[status]

/**
 * base64转blob
 * @param {*} str
 */
export const base64ToBlob = base64String => {
  let bytes = window.atob(base64String.split(',')[1])
  let array = []
  // console.log(`bytes.length=${bytes.length}`)
  for (let i = 0; i < bytes.length; i++) {
    array.push(bytes.charCodeAt(i))
  }
  // console.log(array)
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
}

/*
* ios12版本微信浏览器手机键盘不收回的问题
* */
export const inputBlur = () => {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 0)
}

/*
 * 产生一个select
 */
export const getSelect = (options, config) => {
	return (
		<Select {...config}>
			{options.length > 0 &&
				options.map((n, i) => (
					<Option key={i} value={n.value}>
						{n.name}
					</Option>
				))}
		</Select>
	)
}
