import React, { Component } from 'react'
import { Button, Form, Icon, Modal } from 'antd'
import Upload from './upload'
import './register.css'
const { confirm } = Modal
class PostIDCard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			uploadThis: {},
			loading: false
		}
	}
	/**
	 * 提交表单
	 */
	handleSubmit = e => {
		e = window.event || e
		e.preventDefault()
		this.setState({ loading: true })
		this.state.uploadThis.upload(
			() => {
				this.setState({ loading: false })
				this.props.changeCurrent(3)
			},
			() => {
				this.setState({ loading: false })
			}
		)
		// this.props.changeCurrent(3)
	}

	goBack = () => {
		// 保证用户试用期已经过了
		setTimeout(() => {
			this.props.authorize('', '/survey/station')
		}, 5);
	}

	/**
	 * 获取upload组件this
	 */
	getUploadThis = t => {
		this.setState({ uploadThis: t })
	}
	render() {
		const postIDCard = [
			{
				labelName: '上传身份证',
				itemName: 'idCard',
				options: {
					rules: [
						{
							required: true,
							message: '请选择相关证件'
						}
					],
					validateTrigger: 'onBlur'
				},
				component: <Upload certType={1} getUploadThis={this.getUploadThis} />,
				extra: '请上传身份证正面，保证上传图片清晰，且图片格式为png、jpg、jpeg，图片大小小于5M，以便您尽快通过审核'
			}
		]
		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 6 }
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 }
			}
		}
		const {
			form: { getFieldDecorator }
		} = this.props
		return (
			<div className='register-form'>
				<div className='body'>
					<div className='accinfo'>
						<div>
							{postIDCard.map((item, index) => {
								return (
									<Form.Item
										label={item.labelName}
										hasFeedback={item.hasFeedback ? true : false}
										key={index}
										extra={item.extra}
										labelCol={formItemLayout.labelCol}
										wrapperCol={formItemLayout.wrapperCol}
									>
										{getFieldDecorator(item.itemName, item.options)(item.component)}
									</Form.Item>
								)
							})}
						</div>
						<div className='btn'>
							{
								<Button onClick={this.goBack}>
								{/* <Icon type="left" /> */}
								直接试用
								</Button>
								// localStorage.getItem('wcpa_trial') === '1'? 
								// <Button onClick={this.goBack}>
								// {/* <Icon type="left" /> */}
								// 直接试用
								// </Button> : 
								// <Button onClick={() => {
								// 	this.props.updateState('checkout', 'login')
								// }}>返回登陆</Button>
							}
							<Button type='primary' onClick={this.handleSubmit} loading={this.state.loading}>
								下一步
								<Icon type='right' />
							</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default PostIDCard = Form.create({ name: 'PostIDCard' })(PostIDCard)
