/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-22 14:34:59
 * @LastEditTime: 2019-09-06 16:00:29
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from 'react'
import { Typography, Button } from 'antd'
import './register.css'
const { Text } = Typography

/**
 * 注册须知组件
 */
class Tips extends Component {
	/**
	 * 返回登录界面
	 */
	goBack = () => {
		this.props.updateState('checkout', 'login')
	}
	goOn = () => {
		this.props.changeCurrent(1)
	}

	render() {
		const tips = [
			`欢迎您来到 WCPA
      临床躯体症状分类诊断系统，要想成为系统的用户，请首先了解您的权利与责任，请您仔细阅读以下须知：`,
			`注册账号时您需要提供个人信息如姓名、手机号码、相关证件等，请如实填写。`,
			`手机号码是您登录系统以及忘记密码，取回密码的唯一途径。`,
			`由于诊断的严肃性，请确保您具备相关医学知识，包括但不限于医师，护士，心理咨询/治疗师，医学院在校学生（包括本科，研究生，博士）。`,
			`注册完成后，我们需要审核您填写的内容以及上传的相关证件。`,
			`数据库所有权归属“四川省西部精神医学协会”，个人录入数据使用权归属本人，
      联合使用数据需实名向“四川省西部精神医学协会”提交申请，在尊重参与者各方权益的前提下，进行数据共享。`
		]
		return (
			<div className='body'>
				{tips.map((item, index) => {
					if (index === 0) {
						return (
							<div key={index}>
								<Text strong>{item}</Text>
								<br />
							</div>
						)
					} else {
						return (
							<div key={index}>
								{index}、<Text>{item}</Text>
								<br />
							</div>
						)
					}
				})}
				<Button type='link' style={{ padding: 0 }} onClick={this.goBack}>
					已有账号，点击登录
				</Button>
				<div className='btn'>
					<Button onClick={this.goBack}>离开</Button>
					<Button type='primary' onClick={this.goOn}>
						同意
					</Button>
				</div>
			</div>
		)
	}
}
export default Tips
