import { asyncComponent } from '../utils'
import React from 'react'
import { Modal } from 'antd'

/**
 * 诊断端
 */
const Main = asyncComponent(() => import('../pages/diag/main/main'))
const Solution = React.lazy(() => import('../pages/diag/solution/solution'))
const Arrangement = React.lazy(() => import('../pages/diag/arrangement/arrangement'))
const DoctorComment = React.lazy(() => import('../pages/diag/doctorComment/doctorComment'))
const Qrcode = asyncComponent(() => import('../pages/diag/qrcode/qrcode'))
const User = asyncComponent(() => import('../pages/diag/user/user'))
/**
 * 测评端
 */
const SurveyStation = asyncComponent(() => import('../pages/survey/surveyStation/surveyStation'))
const SurveyInformation = asyncComponent(() => import('../pages/survey/surveyInformation/surveyInformation'))
const SurveyComplete = asyncComponent(() => import('../pages/survey/surveyComplete/surveyComplete'))
/**
 * guest
 */
const Guest = asyncComponent(() => import('../pages/guest/guest'))

/**
 * 下载报告
 */
const DownloadReport = asyncComponent(() => import('../pages/DownloadReport'))

const UploadCert = asyncComponent(() => import('../pages/UploadCert'))

export const routes = [
	{
		path: '/doctor',
		component: Main,
		routes: [
			{
				path: '/doctor/solution',
				component: Solution,
				exact: true,
				icon: 'solution',
				menuText: '报告查看',
			},
			{
				path: '/doctor/arrangement',
				component: Arrangement,
				exact: true,
				icon: 'pushpin',
				menuText: '测评安排'
			},
			{
				path: '/doctor/doctorcomment',
				component: DoctorComment,
				exact: true,
				icon: 'medicine-box',
				menuText: '医生测评'
			},
			{
				path: '/doctor/qrcode',
				component: Qrcode,
				exact: true,
				icon: 'qrcode',
				menuText: '扫码测评'
			},
			{
				path: '/doctor/user',
				component: User,
				exact: true,
				icon: 'user',
				menuText: '账号'
			},
			{
				path: '/doctor/downloadreport',
				component: DownloadReport,
				exact: true,
				icon: 'download',
				menuText: '下载报告',
				warnning: true,
			},
			{
				path: '/doctor/uploadCert',
				component: UploadCert,
				exact: true,
				icon: 'upload',
				menuText: '证件上传',
			},
		]
	},
	{
		path: '/survey/station',
		component: SurveyStation
	},
	{
		path: '/survey/information',
		component: SurveyInformation
	},
	{
		path: '/survey/complete',
		component: SurveyComplete
	},
	{
		path: '/guest',
		component: Guest
	}
]
