/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-12 18:06:49
 * @LastEditTime: 2019-09-05 16:23:47
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from 'react'
import Login from './pages/login/login'
import { ConfigProvider, Modal, notification } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import userDao from './services/dao/userDao'
import { BrowserRouter as Router, Redirect } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { routes } from './router'
import { switchMode } from './config/constant'

import './App.css'
import baseDao from './services/dao/baseDao'
/**
 * 路由分发
 */
class RoutesDistribution extends Component {
	render() {
		return (
			<Router>
				{renderRoutes(routes)}
				<Redirect form='/' to={this.props.routePath} />
			</Router>
		)
	}
}
class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			auth: false,
			routePath: '',
			showLogin: false, //是否渲染登录窗口
			tipRegister: false
		}
	}

	changeTipRegister = bool => {
		this.setState({ tipRegister: bool })
	}
	authorize = (user_code, routePath) => {
		this.getUserInfo(user_code)
		this.setState({ routePath })
	}

	unAuthorize = () => {
		

		this.setState({ showLogin: true }, () => {
			userDao
				.logout()
				.then(res => {
					if (res.message.toUpperCase() === 'OK') {
						localStorage.removeItem('wcpa_userinfo')
						localStorage.setItem('wcpa_status', '')
						localStorage.setItem('wcpa_trial', '')
						this.setState({ auth: false })
					}
				})
				.catch(err => console.log(err))
		})
	}
	/**
	 * 获取用户信息
	 */
	getUserInfo = user_code => {
		userDao
			.getUser()
			.then(res => {
				// console.log(res)
				if (Object.keys(res).length > 0) {
					// localStorage.removeItem('wcpa_userinfo')
					localStorage.setItem('wcpa_userinfo', JSON.stringify([res]))
					localStorage.removeItem('wcpa_signup_phoneNum')
					localStorage.setItem('wcpa_left', res.left)
					localStorage.setItem('wcpa_mode', res.mode)

					const into = () => {
						this.setState(
							{
								auth: true
							},
							() => {
								let userCode = JSON.parse(localStorage.getItem('wcpa_userinfo'))[0].user_code
								let testMap = JSON.parse(localStorage.getItem('wcpa_label')).test_accounts
								if (testMap.indexOf(userCode) !== -1) {
									Modal.confirm({
										title: '提示',
										content: '体验账号仅供体验系统使用，所传数据不具隐私性，且不可下载。建议您进行实名注册后使用',
										onOk: () => {
											this.unAuthorize()
											this.setState({ tipRegister: true })
										},
										okText: '实名注册',
										cancelText: '继续'
									})
								}
							}
						)
					}
					if (switchMode && typeof res.mode === 'string' && res.mode === 'practice' && user_code) {
						Modal.confirm({
							title: '提示',
							content: `您现在正在使用练习模式，如果不再需要练习，可立即开启“正式模式”。`,
							onOk: () => {
								into()
							},
							onCancel: async() => {
								await userDao.toggleWordMode({mode: 'formal'})
								localStorage.setItem('wcpa_mode', 'formal')
								into()
							},
							okText: '继续练习',
							cancelText: '正式使用'
						});
					} else {
						into()
					}
				} else {
					this.unAuthorize()
					Modal.info({
						title: '提示',
						content: '获取用户数据失败，请重新登录'
					})
				}
			})
			.catch(err => {
				console.log(err)
				if (typeof err === 'string' && err.toUpperCase() === 'FORBIDDEN') {
					notification.info({
						message: '提示',
						description: '登录已过期，请重新登录'
					})
				} else {
					Modal.info({
						title: '提示',
						content: '获取用户数据失败，请重新登录'
					})
				}
				this.unAuthorize()
			})
	}

	componentDidMount() {
		/**
		 * 记录不支持localstorage的浏览器
		 */
		if (
			window.localStorage &&
			(localStorage.setItem('wcpa_support', '123'), localStorage.getItem('wcpa_support') === '123')
		) {
			// console.log('localStorage_support')
		} else {
			baseDao
				.recordUnsupported({ agent: window.navigator.userAgent })
				.then(res => console.log('localStorage_not_support'))
		}
		/**
		 * 跳转到二维码路由
		 */
		if (window.location.pathname === '/guest') {
			this.setState({ routePath: `/guest${window.location.hash}`, auth: true })
			return
		}
		/**
		 * 自动登录
		 */
		if (localStorage.getItem('wcpa_userinfo')) {
			let pathname = window.location.pathname === '/' ? '/survey/station' : window.location.pathname
			let wcpa_userinfo = JSON.parse(localStorage.getItem('wcpa_userinfo'))[0]
			this.authorize(wcpa_userinfo.user_code, pathname)
			return
		}
		this.setState({ showLogin: true })
	}

	render() {
		const { auth, routePath, showLogin, tipRegister } = this.state
		if (auth) {
			return (
				<ConfigProvider locale={zhCN}>
					<RoutesDistribution routePath={!!routePath ? routePath : '/guest'} />
				</ConfigProvider>
			)
		}
		return showLogin ? (
			<ConfigProvider locale={zhCN}>
				<Login
					authorize={this.authorize}
					unAuthorize={this.unAuthorize}
					tipRegister={tipRegister}
					changeTipRegister={this.changeTipRegister}
				/>
			</ConfigProvider>
		) : null
	}
}

export default App
