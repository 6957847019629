import React, { Component } from 'react'
import { Form, Input, Button, Modal } from 'antd'
import './ResetPwd.css'
import userDao from '../../services/dao/userDao'

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
}
const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 18,
			offset: 6
		}
	}
}
class ResetPwd extends Component {
	constructor(props) {
		super(props)
		this.state = {
			phoneCode: false, //禁用获取验证码按钮
			phoneCodeCountDown: 60 //获取验证码倒计时
		}
	}
	/**
	 * 手机号码错误，发送验证码按钮不可点击
	 */
	hasErrors = fieldsError => {
		return Object.keys(fieldsError).some(field => fieldsError[field])
	}
	/**
	 * 验证手机验证码
	 */
	validatePhoneCode = (rule, value, callback) => {
		if (!value) {
			callback()
			return
		}
		if (value.length < 6) {
			callback('请输入6位验证码')
			return
		}
		const exp = /^[0-9]*$/
		let validateResult = exp.test(value)
		if (!validateResult) {
			callback('请输入正确的验证码')
			return
		}
		callback()
	}
	/**
	 * 验证手机号码
	 */
	validatePhoneNumber = (rule, value, callback) => {
		if (!value) {
			callback()
			return
		}
		const exp = /^[1]([3-9])[0-9]{9}$/
		let validateResult = exp.test(value)
		if (!validateResult) {
			callback('请输入正确的手机号码')
			return
		}
		callback()
	}
	/**
	 * 确认密码失焦事件
	 */
	handleConfirmBlur = e => {
		const { value } = e.target
		this.setState({ confirmDirty: this.state.confirmDirty || !!value })
	}
	/**
	 * 验证确认密码
	 */
	compareToFirstPassword = (rule, value, callback) => {
		const { form } = this.props
		if (value && value !== form.getFieldValue('password')) {
			callback('两次输入密码不一致')
			return
		} else {
			callback()
			return
		}
	}

	/**
	 *验证登录密码
	 */
	validateToNextPassword = (rule, value, callback) => {
		const { form } = this.props
		if (value && this.state.confirmDirty) {
			form.validateFields(['confirm'], { force: true })
		}
		if (!value) {
			callback()
			return
		}
		if (value.length < 4 || value.length > 20) {
			callback('登录密码长度为4位到20位')
			return
		}
		const exp = /^[a-zA-Z0-9_!@#$%^&*+=-]+$/i
		let validateResult = exp.test(value)
		if (!validateResult || value.indexOf(' ') !== -1) {
			callback('登录密码包含数字字母，以及特殊字符（_!@#$%^&*+=-）')
			return
		}
		callback()
	}
	/**
	 * 获取手机验证码
	 */
	getPhoneNumber = () => {
		this.props.form.validateFields(['phoneNumber'], (err, value) => {
			if (!err) {
				userDao
					.getResetPhoneCode({}, value.phoneNumber)
					.then(res => {
						this.setState({ phoneCode: true })
						this.timer = setInterval(() => {
							if (this.state.phoneCodeCountDown !== 0) {
								this.setState((prevState, props) => ({
									phoneCodeCountDown: prevState.phoneCodeCountDown - 1
								}))
							} else {
								clearInterval(this.timer)
								this.setState({ phoneCode: false, phoneCodeCountDown: 60 })
							}
						}, 1000)
					})
					.catch(err => {
						console.log('getPhoneCode：' + err)
						if (err.code === 1001) {
							Modal.error({
								title: '错误',
								content: '手机号码尚未注册，请确认后再试'
							})
						} else if (err.code === 4420) {
							Modal.error({
								title: '错误',
								content: '抱歉，此手机号码已超出短信发送限制，请稍后再试'
							})
						} else {
							Modal.error({
								title: '错误',
								content: '获取手机验证码失败，请稍后再试'
							})
						}
					})
			}
		})
	}
	/**
	 * 返回登录
	 */
	goBack = () => {
		this.props.updateState('checkout', 'login')
	}
	/**
	 * 提交表单
	 */
	handleSubmit = e => {
		e = window.event || e
		e.preventDefault()
		// this.props.changeCurrent(2)
		const { validateFieldsAndScroll } = this.props.form
		validateFieldsAndScroll((err, value) => {
			if (!err) {
				const { phoneNumber, confirm, phoneCode } = value
				let params = {
					user_code: phoneNumber,
					new_password: confirm,
					verification: phoneCode
				}
				userDao
					.resetPwd(params)
					.then(() => {
						const that = this
						Modal.success({
							title: '提示',
							content: '重置密码成功，请您妥善保管您的密码',
							onOk() {
								if (!!localStorage.getItem('wcpa_defaultRoute')) {
									// let routePath = localStorage.getItem('wcpa_defaultRoute')
									that.props.authorize(phoneNumber, '/survey/station')
									return
								}
								that.props.updateState('userCode', phoneNumber)
								that.props.updateState('checkout', 'pick')
							}
						})
					})
					.catch(err => {
						console.log('sinup:' + err)
						if (err.code === 4404) {
							Modal.error({
								title: '错误',
								content: err.problems
							})
						} else {
							Modal.error({
								title: '错误',
								content: '重置密码错误，请稍后再试'
							})
						}
					})
			}
		})
	}
	componentWillUnmount() {
		clearInterval(this.timer)
	}
	render() {
		const formConfig = [
			{
				labelName: '手机号码',
				itemName: 'phoneNumber',
				options: {
					rules: [
						{
							required: true,
							message: '手机号码不能为空'
						},
						{
							validator: this.validatePhoneNumber
						}
					]
					// validateTrigger: 'onBlur'
				},
				component: (
					<div>
						<Input placeholder='请输入手机号码' maxLength={11} autoFocus />
						<Button
							onClick={this.getPhoneNumber}
							disabled={this.hasErrors(this.props.form.getFieldsError(['phoneNumber'])) || this.state.phoneCode}
						>
							获取验证码
						</Button>
					</div>
				),
				extra: this.state.phoneCode ? `请等待 ${this.state.phoneCodeCountDown} 秒后再次获取验证码` : ''
			},
			{
				labelName: '验证码',
				itemName: 'phoneCode',
				options: {
					rules: [
						{
							required: true,
							message: '验证码不能为空'
						},
						{
							validator: this.validatePhoneCode
						}
					]
					// validateTrigger: 'onBlur'
				},
				component: <Input placeholder='请输入验证码' maxLength={6} />,
				hasFeedback: true
			},
			{
				labelName: '登录密码',
				itemName: 'password',
				options: {
					rules: [
						{
							required: true,
							message: '登录密码不能为空'
						},
						{
							validator: this.validateToNextPassword
						}
					]
					// validateTrigger: 'onBlur'
				},
				component: <Input.Password placeholder='请输入密码' maxLength={20} />,
				hasFeedback: true
			},
			{
				labelName: '确认密码',
				itemName: 'confirm',
				options: {
					rules: [
						{
							required: true,
							message: '确认密码不能为空'
						},
						{
							validator: this.compareToFirstPassword
						}
					]
					// validateTrigger: 'onBlur'
				},
				component: <Input.Password placeholder='请确认密码' maxLength={20} onBlur={this.handleConfirmBlur} />,
				hasFeedback: true
			}
		]
		const {
			form: { getFieldDecorator }
		} = this.props
		return (
			<div className='reset-wrap'>
				<h1>重置密码</h1>
				<div className='body'>
					<Form onSubmit={this.handleSubmit} {...formItemLayout}>
						{formConfig.map((item, index) => {
							return (
								<Form.Item
									label={item.labelName}
									hasFeedback={item.hasFeedback ? true : false}
									key={index}
									extra={item.extra}
								>
									{getFieldDecorator(item.itemName, item.options)(item.component)}
								</Form.Item>
							)
						})}
						<Form.Item {...tailFormItemLayout}>
							<Button onClick={this.goBack} style={{ marginRight: 20 }}>
								返回
							</Button>
							<Button type='primary' htmlType='submit' disabled={this.hasErrors(this.props.form.getFieldsError())}>
								确定
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		)
	}
}

export default Form.create({ name: 'ResetPwd' })(ResetPwd)
