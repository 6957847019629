/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-12 18:06:49
 * @LastEditTime: 2019-09-05 14:57:02
 * @LastEditors: Please set LastEditors
 */
import API from '../api'
import ajax from '../axios'
import BASE_URL from '../baseURL'
import axios from 'axios'

const userDao = {
	//获取用户信息
	getUser(data) {
		return ajax('get', API.USER, data)
	},

	//增加用户数据
	addUser(data) {
		return ajax('post', API.USER, data)
	},

	//删除用户数据
	delUser(data) {
		return ajax('delete', API.USER, data)
	},

	//修改解锁密码
	updateUnlockPass(data) {
		return ajax('put', `${API.UNLOCK}`, data)
	},
	//登录
	login(data) {
		return ajax('put', `${API.LOGIN}`, data)
	},

	//登出
	logout(data) {
		return ajax('put', `${API.LOGOUT}`, data)
	},

	//修改登录密码
	updateLoginPass(data) {
		return ajax('put', `${API.CHANGEPASS}`, data)
	},

	//注册新用户
	sinup(data) {
		return ajax('post', `${API.SINUP}`, data)
	},
	//获取手机验证码
	getPhoneCode(data, phoneNum) {
		return ajax('get', `${API.GETPHONECODE}/${phoneNum}`, data)
	},

	//注册上传相关证件
	sinupUpload(formData) {
		let instance = axios.create({
			baseURL: BASE_URL,
			headers: { 'Content-Type': 'multipart/form-data' },
			withCredentials: true
		})
		return instance.post(API.PICTURE, formData)
	},
	//获取手机验证码
	getResetPhoneCode(data, phoneNum) {
		return ajax('get', `${API.GETRESETPHONECODE}/${phoneNum}`, data)
	},
	//重置登录密码
	resetPwd(data) {
		return ajax('put', API.RESETPWD, data)
	},
	//判断手机号码是否被注册
	occupiedPhone(data, phoneNum) {
		return ajax('get', `${API.OCCUPIED_PHONE}/${phoneNum}`, data)
	},

	// 设置用户是否同意通知事项
	termAgreed(data) {
		return ajax('put', `${API.TERM_AGREED}`, data)
	},

	// 查询工作模式
	getWordMode(data) {
		return ajax('get', `${API.WORK_MODE}`, data)
	},

	// 查询工作模式
	toggleWordMode(data) {
		return ajax('put', `${API.WORK_MODE}`, data)
	},

	// 解锁终端
	checkUnlock(data) {
		return ajax('post', `${API.CHECK_UNLOCK}`, data)
	}
}
export default userDao
