import React, { Component } from 'react'
import { Button, Form, Select, Icon, Modal } from 'antd'
import Upload from './upload'

import './register.css'
const { Option } = Select
class PostDocument extends Component {
	constructor(props) {
		super(props)
		this.state = {
			certType: -1,
			uploadThis: {},
			loading: false
		}
	}


	/**
 * 提交表单
 */
	handleSubmit = e => {
		e = window.event || e
		e.preventDefault()
		const { validateFieldsAndScroll } = this.props.form
		validateFieldsAndScroll(['document'], (err, value) => {
			if (!err) {
				this.setState({ loading: true })

				this.state.uploadThis.upload(
					() => {
						this.setState({ loading: false })
						this.props.authorize('', '/survey/station')
						localStorage.setItem("wcpa_status", "unconfirmed")
						// this.props.updateState('userCode', localStorage.getItem('wcpa_signup_phoneNum'))
						// this.props.updateState('checkout', 'pick')
					},
					() => {
						this.setState({ loading: false })

						Modal.info({
							title: '提示',
							content: '请确认相关证件上传成功'
						})
					}
				)
			}
		})


	}
	hasErrors = fieldsError => {
		return Object.keys(fieldsError).some(field => fieldsError[field])
	}
	/**
	 *
	 * 获取证件类型
	 */
	getCertType = () => {
		const { validateFieldsAndScroll } = this.props.form
		validateFieldsAndScroll(['document'], (err, value) => {
			if (!err) {
				this.setState({ certType: parseInt(value.document) })
			}
		})
	}

	/**
	 * 获取upload组件this
	 */
	getUploadThis = t => {
		this.setState({ uploadThis: t })
	}
	/*
	 * 产生一个select
	 */
	getSelect(options) {
		if (options.length === 0) {
			return null
		}
		return (
			<Select style={{ width: 260 }} onSelect={this.getCertType}>
				{options.map((n) => (
					<Option key={n.code} value={n.code}>
						{n.name}
					</Option>
				))}
			</Select>
		)
	}
	render() {
		//配置证件类型
		// const typeOfCertificate = JSON.parse(localStorage.getItem('wcpa_label')).code_map.certcategory || []
		const typeOfCertificate = JSON.parse(localStorage.getItem('wcpa_label')).code_map.certcategory.slice(1, JSON.parse(localStorage.getItem('wcpa_label')).code_map.certcategory.length) || []
		//配置form
		const postDocumentForm = [
			{
				labelName: '上传相关证件',
				itemName: 'document',
				options: {
					rules: [
						{
							required: true,
							message: '请选择相关证件'
						}
					]
				},
				component: this.getSelect(typeOfCertificate),
				colon: true
			},
			{
				labelName: ' ',
				itemName: 'upload',
				options: {},
				component: <Upload certType={this.state.certType} getUploadThis={this.getUploadThis} />,
				extra: '请您选择证件类型后上传对应证件照，请保证上传图片清晰，且图片格式为png、jpg、jpeg，图片大小小于5M，以便您尽快通过审核',
				colon: false
			}
		]

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 6 }
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 }
			}
		}
		const {
			form: { getFieldDecorator }
		} = this.props
		return (
			<div className='register-form'>
				<div className='body'>
					<div className='accinfo'>
						<div>
							{postDocumentForm.map((item, index) => {
								return (
									<Form.Item
										label={item.labelName}
										hasFeedback={item.hasFeedback ? true : false}
										key={index}
										extra={item.extra}
										labelCol={formItemLayout.labelCol}
										wrapperCol={formItemLayout.wrapperCol}
										colon={item.colon ? true : false}
									>
										{getFieldDecorator(item.itemName, item.options)(item.component)}
									</Form.Item>
								)
							})}
						</div>
						<div className='btn'>
							<div></div>
							{/* <Button onClick={this.props.changeCurrent.bind(this, 2)}>
								<Icon type='left' />
								上一步
							</Button> */}
							<Button disabled={this.hasErrors(this.props.form.getFieldsError())} type='primary' onClick={this.handleSubmit} loading={this.state.loading}>
								<Icon type='smile' />
								完成
							</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default PostDocument = Form.create({ name: 'PostDocument' })(PostDocument)
