/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 14:45:04
 * @LastEditTime: 2019-08-28 15:51:25
 * @LastEditors: Please set LastEditors
 */
import axios from 'axios'
import API from '../api'
import ajax from '../axios'
import BASE_URL from '../baseURL'

const quizDao = {
	//增加测评
	addInvolve(data) {
		return ajax('post', API.QUIZ, data)
	},

	//查看quiz
	getQuiz(data) {
		return ajax('get', API.QUIZ, data)
	},

	//查看quiz mobile
	getmobileQuiz(data, limt) {
		return ajax('get', `${API.QUIZ}?limit=${limt}`, data)
	},

	//修改状态 开始start、暂停pause、恢复resume、结束close。
	getQuizState(data, id) {
		return ajax('patch', `${API.QUIZ}/${id}`, data)
	},

	//提交答案
	addAnswer(data, id, how) {
		let instance = axios.create({
			baseURL: BASE_URL,
			headers: { 'Content-Type': 'application/json;charset=UTF-8' }
		})
		return instance.put(`${API.QUIZ}/${id}/answer/${how}`, data)
	},

	//上传图片
	uploadFile(formData, id) {
		let instance = axios.create({
			baseURL: BASE_URL,
			headers: { 'Content-Type': 'multipart/form-data' }
		})
		return instance.post(`${API.QUIZ}/${id}/file`, formData)
	},

	//根据quizid获取答案
	getAnswer(data, id) {
		return ajax('get', `${API.QUIZ}/${id}/answers?limit=40`, data)
	},

	/**
	 * 搜索quiz
	 */
	searchQuiz(data) {
		return ajax('get', API.FIND, data)
	},

	//创建二维码
	createQrcode(data) {
		return ajax('post', API.QRCODE, data)
	},

	//使用二维码token进行登录
	qrcodeEntrance(data) {
		return ajax('put', API.QRCODE_LOGIN, data)
	},

	//重置二维码
	resetQrcode(data) {
		return ajax('put', `${API.QRCODE}/token/forever`, data)
	},

	//匿名测评获取quiz
	qrcodeGetQuiz(data, id) {
		return ajax('get', `${API.QRCODE_GETQUIZ}/${id}`, data)
	},

	//获取没有完成测评的人，自评他评有一个没完成的
	getOnGoingQuiz(data) {
		return ajax('get', `${API.QUIZ}/ongoing`, data)
	},

	//查询报告申请
	queryReportApply(data) {
		return ajax('get', `${API.REPORT}`, data)
	},

	//提出报告申请
	reportApply(data) {
		return ajax('post', `${API.REPORT}`, data)
	},
	//管理员审批报告结果获取
	getApplyReportRes(data, apply_id) {
		return ajax('get', `${API.HANDLEREPORT}/${apply_id}`, data)
	}
}
export default quizDao
