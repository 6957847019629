import React, { Component } from 'react'
import { message, Icon, Modal } from 'antd'
import './upload.css'
import { base64ToBlob } from '../../utils'
import userDao from '../../services/dao/userDao'
// const getIOS = () => {
// 	let ua = navigator.userAgent.toLowerCase()
// 	if (ua.match(/iPhone\sOS/i) === 'iphone os') {
// 		return true
// 	} else {
// 		return false
// 	}
// }
class upload extends Component {
	constructor(props) {
		super(props)
		this.state = {
			// ios: getIOS(),
			imgList: [],
			visible: false,
			fileName: ''
		}
		this._cam = undefined
		this.imgAddToTemp = this.imgAddToTemp.bind(this)
	}

	/*
	 * 拍照完毕，加入临时列表
	 * */
	imgAddToTemp(e) {
		let reader = new FileReader()
		const file = e.target.files[0]
		const size = file.size
		let filevalue = e.target.value
		let index = filevalue.lastIndexOf('.')
		let suffix = filevalue.substring(index + 1)
		if (size >= 5242880) {
			message.error('上传失败，请上传小于5M的图片')
			return 
		}
		if (suffix !== 'jpg' && suffix !== 'png' && suffix !== 'jpeg') {
			message.error('上传失败，请上传格式为jpg、png、jpeg的图片')
			return
		}
		reader.readAsDataURL(file)
		reader.onloadend = () => {
			const dataURL = reader.result
			let { imgList } = this.state
			imgList.push(dataURL)
			this.setState({ fileName: this._cam.files[0].name }, () => {
				this.setState({ imgList })
			})
			// this.upload(dataURL)
		}
	}

	/**
	 * 上传图片事件
	 * @param {fn} resolve 成功回调
	 * @param {fn} reject 失败回调
	 */
	upload(resolve, reject) {
		let { imgList, fileName } = this.state
		if (imgList.length === 0) {
			Modal.info({
				title: '提示',
				content: '抱歉，您还未上传证件，请确保证件上传成功后再进行下一步'
			})
			reject()
			return
		}
		const blob = base64ToBlob(imgList[0])
		let formData = new FormData()
		// const filename = `${order}.jpg`
		// const filename = this._cam.files[0].name
		formData.append('cert_type', this.props.certType)
		formData.append('picture', blob, fileName)
		userDao
			.sinupUpload(formData)
			.then(res => {
				message.success('上传成功')
				// imgList.push(dataURL)
				// this.setState({ imgList })
				// console.log(this.props)
				// this.props.uploadSuccess()
				resolve()
			})
			.catch(err => {
				console.error(err)
				message.error('上传失败，网络出错，请确保网络正常后再试')
				reject()
			})
	}

	componentDidMount() {
		this.props.getUploadThis(this)
	}
	/**
	 * 查看证件大图
	 */
	viewPic = () => {
		this.setState({ visible: true })
	}
	/**
	 * modal关闭
	 */
	handleCancel = () => {
		this.setState({ visible: false })
	}

	/**
	 * 删除证件
	 */
	deletePic = () => {
		this.setState({ imgList: [] })
	}
	render() {
		const { imgList, visible } = this.state

		let camProps = {
			type: 'file',
			accept: 'image/*',
			className: 'upload-input'
		}
		// if (!ios) {
		// 	camProps = Object.assign(camProps, { capture: 'camera' })
		// }
		return (
			<>
				<div className='register-upload-wrap'>
					{imgList.length > 0
						? imgList.map((src, i) => (
							<div className='upload-preview-wrap' key={i}>
								<img src={src} className='upload-preview' alt='' />
								<div>
									<Icon type='eye' onClick={this.viewPic} />
									<Icon type='delete' onClick={this.deletePic} />
								</div>
							</div>
						))
						: null}
					{imgList.length > 0 ? null : (
						<div className='upload-btn'>
							<Icon type='plus' style={{ fontSize: 25, color: '#999' }} />
							<div className='upload-tips'>上传</div>

							<input
								ref={_cam => (this._cam = _cam)}
								{...camProps}
								onChange={this.imgAddToTemp.bind(this)}
								title='点击上传'
							/>
						</div>
					)}
				</div>
				<Modal
					title={null}
					footer={null}
					visible={visible}
					onOk={this.viewPic}
					onCancel={this.handleCancel}
					destroyOnClose
					bodyStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
				>
					{imgList.length > 0
						? imgList.map((src, i) => <img style={{ width: '95%', height: '95%' }} key={i} src={src} alt='' />)
						: null}
				</Modal>
			</>
		)
	}
}

export default upload
